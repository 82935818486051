import React from "react"
import Container from "../../../utils/container"
import MarketsNavigationItem from "./item"

const MarketsNavigationList = ({ data, page }) => {
  return (
    <Container>
      <ol>
        {data.map((item, index) => {
          return <MarketsNavigationItem page={page} item={item} index={index} />
        })}
      </ol>
    </Container>
  )
}

export default MarketsNavigationList
