import * as React from "react"

function RightArrowIcon(props) {
  return (
    <svg
      className={`fill-current text-black group-hover:text-blue md:w-[32px] md:h-[32px] w-[16px] h-[16px]`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
    >
      <g clipPath="url(#prefix__clip0)">
        <path d="M15.546 31.092c8.586 0 15.546-6.96 15.546-15.546C31.092 6.96 24.132 0 15.546 0 6.96 0 0 6.96 0 15.546c0 8.586 6.96 15.546 15.546 15.546z" />
        <path
          d="M19.376 16.487l-5.565 5.538a.69.69 0 01-.972 0l-.649-.646a.681.681 0 010-.966l4.41-4.41-4.412-4.41a.681.681 0 010-.966l.649-.646a.69.69 0 01.972 0l5.567 5.538a.681.681 0 010 .968z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill="#fff" d="M0 0h31.092v31.092H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default RightArrowIcon
