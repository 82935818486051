import React from "react"
import { Link } from "gatsby"
import ctl from "@netlify/classnames-template-literals"
import RightArrowIcon from "./right-arrow"

const MarketsNavigationItem = ({ item, page }) => {
  const { name } = item

  const url = name.replace(/\s/g, "-").toLowerCase()

  const linkStyle = ctl(
    `  md:px-[51px] px-[20px]  md:py-[41px] py-[19px]  flex items-center justify-between`
  )

  const listItemStyle = ctl(
    `border md:rounded-[15px] group rounded-[10px] border-black hover:border-blue hover:text-blue md:mb-[45px] mb-[30px] md:text-[20px] text-[14px] font-bold group last:mb-0`
  )

  return (
    <li className={listItemStyle}>
      <Link to={`/markets/${page}/${url}/`} className={linkStyle}>
        <span>{name}</span>

        <RightArrowIcon />
      </Link>
    </li>
  )
}

export default MarketsNavigationItem
