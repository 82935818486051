import * as React from "react"
import Layout from "../../components/layout"
import MarketsNavigationList from "../../components/pages/markets/navigation"
import mt4MarketsData from "data/mt4-markets.json"
import MarketsPageHeader from "../../components/pages/markets/header"

const Mt4MarketPage = () => {
    return (
        <Layout title="Meta Trader 4" description='Get access to thousands of assets with the tightest spreads when you trade the financial markets on our MT4 platform.'>
            <MarketsPageHeader page="mt4"/>
            <MarketsNavigationList page='mt4' data={mt4MarketsData}/>
        </Layout>
    )
}


export default Mt4MarketPage
