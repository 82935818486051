import React from "react"
import ctl from "@netlify/classnames-template-literals"
import {StaticImage} from "gatsby-plugin-image"
import Container from "../../../utils/container"

const MarketsPageHeader = ({page, specialMargin}) => {
    const marketsPageHeaderStyle = ctl(`wrapper ${specialMargin ? "!mb-[30px]" : ""}`)
    return (
        <header className={marketsPageHeaderStyle}>
            <Container>
                {page === "mt4" && (
                    <StaticImage
                        src="../../../../assets/images/pages/markets/mt4.webp"
                        alt="Meta Trader 4 Markets Banner"
                    />
                )}
                {page === "cloudtrade" && (
                    <StaticImage
                        src="../../../../assets/images/pages/markets/cloudtrade.webp"
                        alt="Cloudtrade Markets Banner"
                    />
                )}
            </Container>
        </header>
    )
}

export default MarketsPageHeader
